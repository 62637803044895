export const menuItems = [
  {
    id: 1,
    label: "ADMIN MENU",
    isTitle: true,
  },
  {
    id: 2,
    label: "menuitems.admin.list.dashboard",
    icon: "ri-dashboard-line",
    link: "/",
  },
  {
    id: 3,
    label: "menuitems.admin.list.daily",
    icon: "ri-file-chart-line",
    link: "/admin/daily-reports",
  },
  {
    id: 4,
    label: "menuitems.admin.list.agents",
    icon: "ri-user-2-line",
    link: "/admin/agents",
  },
  {
    id: 5,
    label: "menuitems.admin.list.transactions",
    icon: "ri-list-check",
    link: "/admin/transactions",
  },
  {
    id: 6,
    label: "menuitems.admin.list.single-transactions",
    icon: "ri-table-line",
    link: "/admin/agent-transactions",
  },
  {
    id: 8,
    label: "menuitems.admin.list.adjustments",
    icon: "ri-arrow-go-back-line",
    link: "/admin/adjustments",
  },
  {
    id: 7,
    label: "menuitems.admin.list.lock",
    icon: "ri-lock-unlock-line",
    link: "/admin/lock",
  },
];


